.lista-container-lobbyReports {
    border-radius: 34px;
    background-color: #f3f4f6;
    padding-bottom: 16px;
    /* Agrega estilos adicionales como sombras, padding, etc., según necesites */
  }
  
  .lista-item-lobbyReports {
    display: flex;
    background-color: #fff;
    margin: 20px;
    padding: 20px;
    border-radius: 24px;
    box-shadow: 0px 0px 19px -3px rgba(0,0,0,0.1);
    align-items: center;
    margin-bottom: 10px; /* Espaciado entre elementos de la lista */
  }
  .lista-item-lobbyReports:hover {
    cursor: pointer;
    background-color: #f3f4f6;
  }

  
  .item-imagen {
    width: 100px; /* Ajusta el tamaño según necesites */
    margin-right: 10px; /* Espaciado entre la imagen y el texto */
  }
  
  .item-texto h3 {
    margin: 0;
    /* Estilos para el título */
  }
  
  .item-texto p {
    /* Estilos para la descripción */
  }

  