.custom-template {
    padding: 30px;
    text-align: center;
}
.containerAccordeon {
    margin-left: 16px;
    display: flex;
    justify-content: space-between;   
    flex-direction: column;
  }
.containerAccordeon-expanded{
    margin-left: 25px;
    display: flex;
    justify-content: space-between;   
    flex-direction: row;
    width: 90%;
}
.k-panelbar > .k-item > .k-link.k-selected, .k-panelbar > .k-panelbar-header > .k-link.k-selected {
  color: black;
  background-color: #ffffff;
}
.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  color: #8f8f8f;
  background-color: #ffffff;
  font-size: large;
}
.k-panelbar > .k-item > .k-link.k-selected:hover, .k-panelbar > .k-panelbar-header > .k-link.k-selected:hover {
  color: black;
  background-color: #ffffff;
}

.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-panelbar-header > .k-link:hover {
  color: black;
  background-color: #edecec;
}
.k-panelbar {
  border-color: rgb(255, 255, 255);
  color: #636363;
  background-color: #ffffff;
  border-style: none  ;
}
  
  @media (max-width: 900px) {
    .containerAccordeon {
        flex-direction: column;
    }
  }
  .k-drawer-item:hover {
    background-color: #ffffff;
  }
  .k-drawer-item {
    padding-block: 10px;
    padding-inline: 0px;
     gap: 0px; 
    
}
  