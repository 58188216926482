
:root {
    --main-bg: #ffffff;
    --second-bg: #fafafb;
    --txt-color: #455560;
    --txt-white: #fff;
    --main-color: #349eff;
    --second-color: #62b4ff;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --font-family: 'Nunito Sans', sans-serif;

    --main-bg-light: #ffffff;
    --second-bg-light: #fafafb;
    --txt-color-light: #455560;
    --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-dark: #2d2d2d;
    --second-bg-dark: #202020;
    --txt-color-dark: #bbbbbb;
    --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

    --main-color-blue: #349eff;
    --second-color-blue: #62b4ff;

    --main-color-red: #fb0b12;
    --second-color-red: #ff4a6b;

    --main-color-cyan: #10d4d2;
    --second-color-cyan: #2ae9e6;

    --main-color-green: #019707;
    --second-color-green: #4caf50;

    --main-color-orange: #d68102;
    --second-color-orange: #fca11a;

    --sidebar-width: 300px;
    --border-radius: 15px;
    --topnav-height: 110px;
    --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: var(--main-color);
}


ul {
    list-style-type: none;
}

input {
    border: 2px solid transparent;
    outline: 0;
}

input:focus {
    border: 2px solid var(--main-color);
}

.page-header {
    margin-bottom: 40px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 24px;
}

.page-header-reports {
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 24px;
    color:#000
}

.card-header-titles {
    font-family: 'Nunito Sans', sans-serif;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 12px;
    color:#000
}

.page-subheader {
    margin-top: 80px;
    margin-bottom: 20px;
    /* text-transform: capitalize; */
    font-weight: 700;
    font-size: 19px;
}

.page-subheader-reports {
    margin-top: 40px;
    margin-bottom: 20px;
    /* text-transform: capitalize; */
    font-weight: 700;
    font-size: 19px;
}

.card {
    padding: 30px;
    margin-bottom: 30px;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    width: 100%;
}

.full-height {
    height: 100%;
}

.card.full-height {
    height: calc(100% - 30px);
}

.card__header {
    text-transform: capitalize;
}

.card > div ~ div {
    margin-top: 30px;
}

.card__footer {
    text-align: center;
    text-transform: capitalize;
}

.light-background {
    background-color: var(--main-bg-light);
    color: #000;
}

.dark-background {
    background-color: var(--main-bg-dark);
    color: #fff;
}

.blue-color {
    background-color: var(--main-color-blue);
    color: #fff;
}

.red-color {
    background-color: var(--main-color-red);
    color: #fff;
}

.cyan-color {
    background-color: var(--main-color-cyan);
    color: #fff;
}

.green-color {
    background-color: var(--main-color-green);
    color: #fff;
}

.orange-color {
    background-color: var(--main-color-orange);
    color: #fff;
}