.scheduled-call-form {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .scheduled-call-form h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .scheduled-call-form div {
    margin-bottom: 15px;
  }
  
  .scheduled-call-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .scheduled-call-form input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .scheduled-call-form button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .scheduled-call-form button:hover {
    background-color: #45a049;
  }
  
  .scheduled-call-form p {
    color: red;
    font-weight: bold;
  }
  
  .scheduled-call-form table {
    width: 100%;
    margin-top: 20px;
  }
  
  .scheduled-call-form table td {
    padding: 8px;
  }
  