.btnNavigation {
    border: 1px solid #00244c !important;
    border-radius: 5px !important;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 16px 8px !important;
    margin: 3px !important;
}

.select {
    width: 98% !important;    
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);    
    margin-bottom: 10px !important;
}