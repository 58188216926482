.list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }
  
  .list-group-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .list-group-item svg {
    margin-right: 5px;
    color: #007bff; /* Puedes cambiar este color según tu esquema de diseño */
  }