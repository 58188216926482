input[type='file'] {
	display: none;
}

.siignalFileUploadCenterLabel {
	border: dashed #005ec7 5px;
	text-align: center;
	padding-top: 7vh;
	width: 100%;
	height: 25vh;
	margin: 30px 0px 15px 0px;
	cursor: pointer;
	border-radius: 30px;
	font-size: 30px;
	font-family: 'Nunito Sans';
	font-weight: 600;
}
.uploadCenterCell {
	border-color: black;
	border-style: solid;
	border-width: 1px;
	padding: 2px 0 2px 0;
	text-align: center;
}
