.card-dash{
    padding: 10px;
}
.title-card-dash {
    font-size: 1.5rem;
    height: 45px;
    display: flex;
    align-items: center;
    color: #1d1d1d;
    justify-content: center;
    margin: auto;
}
.number-card-dash {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.9rem;
    color: #666666;
}
@media (max-width: 1300px) {
    .title-card-dash {
        font-size: 1.2rem;
        height: 35px;
    }
    
    .number-card-dash {
        font-size: 1.5rem;
    }
}