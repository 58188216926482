/* COLORS BASED ON BRAND IMAGE */
:root {
	/* Opacity Levels */
	--opacity-100: 1;
	--opacity-75: 0.75;
	--opacity-50: 0.5;
	--opacity-25: 0.25;
	--opacity-10: 0.1;

	--siigo-white: #ffffff;
	--siigo-black: #000000;

	/* Additional Color */
	--siigo-black-100: rgba(0, 0, 0, var(--opacity-100));
	--siigo-black-75: rgba(0, 0, 0, var(--opacity-75));
	--siigo-black-50: rgba(0, 0, 0, var(--opacity-50));
	--siigo-black-25: rgba(0, 0, 0, var(--opacity-25));
	--siigo-black-10: rgba(0, 0, 0, var(--opacity-10));

	/* Siigo Colors */
	--siigo-blue-100: rgba(0, 170, 255, var(--opacity-100));
	--siigo-blue-75: rgba(0, 170, 255, var(--opacity-75));
	--siigo-blue-50: rgba(0, 170, 255, var(--opacity-50));
	--siigo-blue-25: rgba(0, 170, 255, var(--opacity-25));
	--siigo-blue-10: rgba(0, 170, 255, var(--opacity-10));

	--siigo-blue-dark-100: rgba(0, 36, 76, var(--opacity-100));
	--siigo-blue-dark-75: rgba(0, 36, 76, var(--opacity-75));
	--siigo-blue-dark-50: rgba(0, 36, 76, var(--opacity-50));
	--siigo-blue-dark-25: rgba(0, 36, 76, var(--opacity-25));
	--siigo-blue-dark-10: rgba(0, 36, 76, var(--opacity-10));

	--siigo-white-100: rgba(255, 255, 255, var(--opacity-100));
	--siigo-white-75: rgba(255, 255, 255, var(--opacity-75));
	--siigo-white-50: rgba(255, 255, 255, var(--opacity-50));
	--siigo-white-25: rgba(255, 255, 255, var(--opacity-25));
	--siigo-white-10: rgba(255, 255, 255, var(--opacity-10));

	--siigo-green-100: rgba(122, 194, 58, var(--opacity-100));
	--siigo-green-75: rgba(122, 194, 58, var(--opacity-75));
	--siigo-green-50: rgba(122, 194, 58, var(--opacity-50));
	--siigo-green-25: rgba(122, 194, 58, var(--opacity-25));
	--siigo-green-10: rgba(122, 194, 58, var(--opacity-10));

	--siigo-orange-100: rgba(255, 165, 50, var(--opacity-100));
	--siigo-orange-75: rgba(255, 165, 50, var(--opacity-75));
	--siigo-orange-50: rgba(255, 165, 50, var(--opacity-50));
	--siigo-orange-25: rgba(255, 165, 50, var(--opacity-25));
	--siigo-orange-10: rgba(255, 165, 50, var(--opacity-10));

	--siigo-yellow-100: rgba(255, 201, 51, var(--opacity-100));
	--siigo-yellow-75: rgba(255, 201, 51, var(--opacity-75));
	--siigo-yellow-50: rgba(255, 201, 51, var(--opacity-50));
	--siigo-yellow-25: rgba(255, 201, 51, var(--opacity-25));
	--siigo-yellow-10: rgba(255, 201, 51, var(--opacity-10));

	--siigo-red-100: rgba(247, 39, 78, var(--opacity-100));
	--siigo-red-75: rgba(247, 39, 78, var(--opacity-75));
	--siigo-red-50: rgba(247, 39, 78, var(--opacity-50));
	--siigo-red-25: rgba(247, 39, 78, var(--opacity-25));
	--siigo-red-10: rgba(247, 39, 78, var(--opacity-10));

	--siigo-purple-100: rgba(85, 12, 145, var(--opacity-100));
	--siigo-purple-75: rgba(85, 12, 145, var(--opacity-75));
	--siigo-purple-50: rgba(85, 12, 145, var(--opacity-50));
	--siigo-purple-25: rgba(85, 12, 145, var(--opacity-25));
	--siigo-purple-10: rgba(85, 12, 145, var(--opacity-10));

	--siigo-pink-100: rgba(199, 56, 121, var(--opacity-100));
	--siigo-pink-75: rgba(199, 56, 121, var(--opacity-75));
	--siigo-pink-50: rgba(199, 56, 121, var(--opacity-50));
	--siigo-pink-25: rgba(199, 56, 121, var(--opacity-25));
	--siigo-pink-10: rgba(199, 56, 121, var(--opacity-10));
}

/* Add this to your CSS file */
.loading-center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 70px);
	/* Set the height to cover the full viewport height */
}

.loading-container {
	background-color: black;
	color: white;
	font-family: monospace;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	width: 80%;
	font-size: 12px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
}

.loading-container::before {
	content: "Renewal Flow Pro - v1.0";
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	font-size: 14px;
}

.loading-container p {
	margin: 5px 0;
}



.full {
	width: 100%;
	float: left;
	height: 100vh;
	box-sizing: border-box;
	border-right: 1px solid #ccc;
}

/* center a div horizontall and vertical */
.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.edit {
	/* fixed at center of screen */
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	height: auto;
	margin-top: -100px;
	margin-left: -200px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	background: #fff;
	padding: 10px;
	z-index: 1;
}

.edit input,
.editCard input {
	border: #0077c2 solid 1px;
	border-radius: 5px;
	width: 100%;
	height: 30px;
	margin: 5px 0;
	box-sizing: border-box;
}

.edit h4,
.editCard h4 {
	margin: 0;
	padding: 0;
	font-size: 1.5rem;
}

.edit .close,
.editCard .close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.5rem;
	cursor: pointer;
	border: solid black 1px;
	width: 20px;
	height: 20px;
	font-size: 14;
	text-align: center;
	line-height: 20px;
	background: red;
	color: white;
}

.formBody {
	margin: auto;
	width: 90%;
	box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
	padding: 20px 10px 20px 10px;
}

.formBody>div {
	padding: 5px;
}

/* Breadcrumb container */
.renewals-breadcrumb-container {
	display: flex;
	align-items: center;
	margin: 20px 0;
}

/* Breadcrumb item */
.renewals-breadcrumb-item {
	margin-right: 10px;
	text-decoration: none;
	transition: color 0.2s ease-in-out;
	cursor: pointer;
}

/* Last breadcrumb item */
.renewals-breadcrumb-item:last-child {
	font-weight: bold;
}

/* Separator */
.renewals-breadcrumb-separator {
	margin: 0 10px;
}

/* Separator icon */
.renewals-breadcrumb-separator::before {
	content: '\2192';
	/* Right arrow symbol */
}

/* Breadcrumb item active */
.renewals-breadcrumb-item.active {
	font-weight: bold;
}

.title {
	color: black;
}

.renewals-optionLabel {
	width: 100%;
	font-weight: bold;
	display: inline-block;
	cursor: pointer;
	padding-top: 15px;
	padding-bottom: 15px;
	margin: -8px;
}

.renewals-OptionElement input {
	display: none;
}

.renewals-OptionElement {
	margin: 5px;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

textarea {
	width: 100%;
	height: 50px;
}


.Tools {
	display: inline;
}

.inline-checkbox {
	display: inline-block;
	margin-right: 10px;
	width: 30%;
	margin: auto;
	/* border: solid rgb(28, 89, 188) 1px; */
	/* border-radius: 10px; */
}

.inline-checkbox label {
	display: inline-block;
	cursor: pointer;
	padding-left: 25px;
	position: relative;
}

.inline-checkbox input[type='checkbox']:checked+label:before {
	background-color: #0077c2;
}

.inline-checkbox-group {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 400px;
	margin: auto;
}

.scaleSlicer {
	position: fixed;
	z-index: 99999999999;
	bottom: 50px;
	left: -40px;
	rotate: -90deg;
	width: 150px;
}

input[type='range'] {
	-webkit-appearance: none;
	margin: 18px 0;
	width: 100%;
	border: solid 1px #00244c;
	width: 100%;
}

.canvasContainer {}

.editorContainer {
	border: solid purple 1px;
}

.renewals-btnSwitch {
	z-index: 99999999999;
	position: fixed !important;
	display: inline-block;
	rotate: -90deg;
	width: 600px;
	right: -280px;
	bottom: 50%;
}

.renewals-btnSwitch button {
	margin-left: 10px !important;
}

.kendoCanvasContainer {
	/* border: solid greenyellow 1px; */
}

.showHideControl {
	width: 180px;
	position: relative;
	top: 90;
	rotate: -90deg;
	left: -110px;
}

.editorFormContainer {
	position: relative;
	top: -37px;
}

.editCard {
	height: calc(100vh - 80px);
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #ccc;
	background: #fff;
	z-index: 1;
	overflow-y: scroll;
}

.hidden {
	display: none !important;
}

.showEditorButton {
	z-index: 99999999999;
	position: relative !important;
	display: inline-block;
	rotate: -90deg;
	width: 180px;
	right: 110px;
	top: 70px;
}

.showEditorButtonHidden {
	z-index: 99999999999;
	position: fixed !important;
	rotate: -90deg;
	width: 180px;
	right: -70px;
	top: 80px;
}

.fieldset-agent-renewals {
	width: 100%;
}

.neutralize-css {
	all: unset;
}

.neutralize-css>* {
	all: unset;
}

.renewals-tree-instance {
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
}

.renewals-tree-title {
	font-size: 1.5rem;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.renewals-tree-title-img {
	width: 100px;
	height: 100px;
}

.renewals-tree-subtitle {
	font-size: 1.2rem;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.renewals-tree-card-title {
	font-size: 1.3rem;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}

.testClass {
	border: solid #ff1aff 3px;
}

.renewals-border-blue {
	border: solid 1px var(--siigo-blue-100);
}

.renewals-border-green {
	border: solid 1px var(--siigo-green-100);
}

.renewals-border-red {
	border: solid 1px var(--siigo-red-100);
}

.renewals-border-orange {
	border: solid 1px var(--siigo-orange-100);
}

.renewals-border-purple {
	border: solid 1px var(--siigo-purple-100);
}

.renewals-border-pink {
	border: solid 1px var(--siigo-pink-100);
}





.renewals-font-blue {
	color: var(--siigo-blue-100);
}

.renewals-font-green {
	color: var(--siigo-green-100);
}

.renewals-font-red {
	color: var(--siigo-red-100);
}

.renewals-font-orange {
	color: var(--siigo-orange-100);
}

.renewals-font-purple {
	color: var(--siigo-purple-100);
}

.renewals-font-pink {
	color: var(--siigo-pink-100);
}

.nitSearchREsult {
	margin: auto;
	border-collapse: collapse;
	width: 100%;
}

.nitSearchREsult th,
.nitSearchREsult td {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

.nitSearchREsult th {
	background-color: #f2f2f2;
}

/* all images inside .renewals-tree-html-container at any level */
.renewals-tree-html-container img {
	width: 100%;
	display: block;
}

.siignal-buttons-area button {
	float: right;
}

.adminFormInput {
	border: solid var(--siigo-blue-50) 1px;
	border-radius: 5px;
}

.adminFormOptionContainer {
	border: solid var(--siigo-blue-75) 1px;
	border-radius: 4px;
	padding: 8px;
}

.renewalsInputDateLabel {
	position: relative;
	z-index: 9999999999;
	background: white;
	left: 10px;
}

.renewalsInputDate {
	border: solid #c4c4c4 1px;
	width: 100%;
	padding: 10px;
	border-radius: 6px;
	position: relative;
	top: -16px;
}

.renewalsAgentPanel-table {
	width: 100%;
	margin: auto;
	border-collapse: collapse;
}

.renewalsAgentPanel-th {
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	text-align: center;
	padding: 5px 5px 5px 5px;
	font-size: 12px;
}

.renewalsAgentPanel-td {
	border: 1px solid #ccc;
	padding: 3px 3px 3px 3px;
	font-size: 10px;
}

.renewalsAgentPanel-space {
	height: 5px;
}


.renewalsTree-promiseIsAlmostExpired {
	background: var(--siigo-red-50);
	transition: all 0.3s ease;
}

.renewalsTree-promiseIsAlmostExpired:hover {
	font-size: 12px;
}

.renewalsTree-promiseIsPaid {
	background: var(--siigo-green-75);
	transition: all 0.3s ease;
}

.renewalsTree-promiseIsPaid:hover {
	font-size: 12px;
}

.renewalsTree-promiseIsExpired {
	background: var(--siigo-red-75);
	transition: all 0.3s ease;
}

.renewalsTree-promiseIsExpired:hover {
	font-size: 12px;
}

.renewalsDataPopUp {
	display: flex;
	/* Add flex display */
	flex-direction: column;
	/* Stack child elements vertically */
	border: solid var(--siigo-green-100) 1px;
	position: fixed;
	top: 19vh;
	bottom: 20vh;
	background: white;
	left: 20vw;
	width: 60vw;
	padding: 15px;
	border-radius: 8px;
	z-index: 2147483647;
}

.renewalsDataPopUp .buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.renewalsDataPopUp .buttons button {
	width: 50%;
}

.renewalsDataPopUp textarea {
	border: solid var(--siigo-black-25) 1px;
	padding: 15px;
	border-radius: 8px;
	flex: 1;
	/* Allow the textarea to grow and occupy available vertical space */
}

.renewalsDataPopUp button {
	border: solid var(--siigo-black-25) 1px;
	background: var(--siigo-red-75);
	padding: 5px 10px;
	border-radius: 8px;
	float: right;
	z-index: 9999999999999999999;
}

.renewals-tableResume {
	width: calc(100% - 5px);
	margin: auto;
}

.renewals-tableResume .success {
	background: #9cca73;
}

.renewals-tableResume .danger {
	background: #d67889;
}

.renewals-tableResume th,
.renewals-tableResume td,
.renewalsAgentPanel-table th,
.renewalsAgentPanel-table td {
	border-collapse: collapse;
	border: solid 1px var(--siigo-blue);
	padding: 3px 8px;
}

.tabContainer .k-animation-container {
	min-width: 100%;
}

.renewalsTreeBtn,
.tipoGestionLegend div {
	display: flex;
}

.renewalsTreeBtn button,
.tipoGestionLegend div .k-chip,
.tipoGestionLegend div .leyenda {
	flex: 1;
	box-sizing: border-box;
}

.renewalsAgentPanel-td .header {
	font-weight: 600;
	font-size: 12px;
}

.mxRenewalsInputCont {
	width: 100%;
}

.mxRenewalsInput {
	border: var(--siigo-blue-50) solid 1px;
	padding: 3px;
	width: calc(100% - 50px);
}

.siignalRenewalsTable {
	margin: auto;
	font-family: arial, sans-serif;
	border-collapse: collapse;
	min-width: 100% !important;
}

.siignalRenewalsTable td,
.siignalRenewalsTable th {
	border: 1px solid #a8a8a8;
	text-align: left;
	padding: 5px;
}

.siignalRenewalsTable tr:nth-child(even) {
	background-color: #dddddd;
}

.siigoRenewalsTitle {
	font-size: 16px;
}

.siignalRenewalsMarginTop {
	margin-top: 10px;
}

.siigoRenewalsButton {
	text-align: center;
}

.siignalRenewalSaveAdminButton {
	border: solid #00244c 1px;
	border-radius: 5px;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 10px;
}

.siignalPointer {
	cursor: pointer;
}