.cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding-bottom: 16px !important;
    box-sizing: border-box;
    border: 1px solid #00244c;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.bigNumber {
    font-size: 30px !important;
    font-weight: 'bold' !important;
}

.label {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.paddingTop {
    padding-top: 15px;
    margin: auto;
}