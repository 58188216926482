
  .ChunkBargrid-container {
    display: flex;
    width: 724px;
    position: relative;
    overflow: visible;
    gap: 5px;
    padding: 20px 80px 20px 80px;
  }
  
  .ChunkBargrid-column {
    /* border: 1px solid #ccc; */
    padding: 10px;
    background-color: #efefef;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
}
  
  .ChunkBargrid-date {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .ChunkBargrid-item {
    width: 40px;
    height:40px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #4CAF50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .CHGreen{
    background-color: #4CAF50;
  }
  .CHYellow{
    background-color: #FFC107;
  }
  .CHRed{
    background-color: #f44336;
  }
