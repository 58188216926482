.dashboard-card-content-number {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 5rem;
    line-height: 80px;
    text-align: center;
}
.dashboard-card-content-number-price {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 3.5rem;
    line-height: 80px;
    padding-top: 20px;
    text-align: center;
}
.dashboard-card-content-number-price_small {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 80px;
    padding-top: 10px;
    text-align: center;
}
@media (max-width: 1600px) {
    .dashboard-card-content-number {
        font-size: 4rem; /* Reducir el tamaño de fuente aquí */
    }
    .dashboard-card-content-number-price{
        font-size: 2rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }
    .dashboard-card-content-number-price_small{
        font-size: 1.4rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }

}
@media (max-width: 1300px) {
    .dashboard-card-content-number {
        font-size: 3rem; /* Tamaño de fuente para pantallas hasta 1300px */
    }
    .dashboard-card-content-number-price{
        font-size: 1.5rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }
    .dashboard-card-content-number-price_small{
        font-size: 1.2rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }
}
@media (max-width: 1000px) {
    .dashboard-card-content-number {
        font-size: 2rem; /* Tamaño de fuente para pantallas hasta 1300px */
    }
    .dashboard-card-content-number-price{
        font-size: 1rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }
    .dashboard-card-content-number-price_small{
        font-size: 0.9rem; /* Reducir el tamaño de fuente aquí */
        padding-top: 0px;
    }
}
.dashboard-card-content {
    text-align: center;
}
.columns-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  overflow-y: auto; /* Añade scroll vertical si es necesario */
}
@media (max-width: 1600px) {
  .columns-home {
    margin-top: 0px;
  }
}
.linea-home{
  background: #8b8b8b;
  height:1.5px;
  margin: -5px 0px 9px 0px;
  width:135px;
  margin-left:30px;
}
@media (max-width: 1900px) {
  .linea-home{
    margin: -10px 0px 3px 0px;
    width:75px;
    margin-left:20px;
  }
}
.VentasNubeStyle{
    padding-top: 10px;
}
.VentasNubeStyle p{
    color: #8b8b8b;
}
.gray {
    color: #8b8b8b66;
}
.green {
    color: #37b400;
}
.red {
    color: #f31700;
}
 
.orange {
    color: #ffa322;
}
.blue {
    color: #4726ff;
}
.gauge-div {
    margin-top: -60px;
    text-align: center;
}
.gauge-footer {
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 42px;
}
.footer-home {
    font-size: 1.6rem;
}
.footer-home span{
    font-weight: bold;
    font-size: 1.8rem;
}
@media (max-width: 1600px) {
    .footer-home {
        font-size: 1rem; /* Reducir el tamaño de fuente aquí */
    }
    .footer-home span{
        font-size: 1.3rem; /* Reducir el tamaño de fuente aquí */
    }
}
 
.k-card {
    border-style: none;
    box-shadow: 0px 0px 8px 6px rgba(227, 227, 227, 0.42) !important
}
.k-card-header {
    background-color: #ffffff;
    border-bottom: none !important;
    text-align: center;
    padding: 1px 24px;
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.0125em;
    color: #333333;
}
.k-card-body {
    padding-block: 1px !important;
    padding-inline: 16px;
    outline: 0;
    flex: 1 1 auto;
}
.k-tilelayout {
    background-color: #ffffff;
}
.titulo-home {
    text-align: left;
    font-family: 'Nunito';
    letter-spacing: 0.0225em;
    font-weight: bold;
}
.titulo-home span{
    text-align: left;
    font-family: 'Nunito';
    letter-spacing: 0.0225em;
    font-weight: lighter;
}
.li-customer {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    margin-top: 5px;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    color: #333333;
}
.ul-customer {
    list-style-type: none;
    margin-top: 18px;
}
@media (max-width: 1600px) {
    .li-customer {
        font-size: 1rem; /* Reducir el tamaño de fuente aquí */
        margin-top: 0px;
    }
    .ul-customer {
        margin-top: 0px;
    }
}
.card-content {
    margin-top: 50px;
    max-height: 220px; /* Altura máxima antes de que se active el scroll */
    overflow-y: auto; /* Activa el scroll vertical si el contenido excede max-height */
}
@media (max-width: 1600px) {
    .card-content {
        margin-top: 0px;
    }
}
.AverageCreacionRecomendados{
    font-family: 'Nunito Sans';
    width: 120px;
    margin-top: 25px;
    font-style: normal;
    letter-spacing: 0.0125em;
    color: #8b8b8b;
}
@media (max-width: 1600px) {
    .AverageCreacionRecomendados {
        margin-top: 15px;
    }
}
.todoList-Style{
    max-height: 490px;
    overflow-y: auto;
}
.icon-home svg {
    width: 32px;  
    height: 32px;
}
@media (max-width: 1600px) {
    .todoList-Style {
        max-height: 240px;
    }
    .icon-home svg {
        width: 22px;  
        height: 22px;
    }
}
 
.tres-columnas-home {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Crea tres columnas */
  gap: 10px; /* Espacio entre columnas */
  list-style: none; /* Elimina los puntos de la lista */
  padding: 0; /* Elimina el padding por defecto */
}
 
.li-reports-home {
  /* Estilos para los elementos de la lista (opcional) */
  border: 1px solid #00234c20;
  border-radius: 7px;
  padding: 1px;
  text-align: center;
}
.li-reports-home:hover {
  background-color:#e5e5e5;
  cursor: pointer;
}
@media screen and (min-width: 1500px) {
  .li-reports-home {
    font-size: 1rem;
    border-radius: 7px;
  }
}
.CrossSellTable {
    border-collapse: collapse;
    border: 2px solid rgb(140 140 140);
    font-family:Nunito;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    font-size: 1rem;
    text-align: center;
  }
  .CrossSellTable td{
    background-color: #fefefe;
    border: 1px solid rgb(255, 255, 255);
    padding: 0px;
  }
    .CrossSellTable th {
        background-color: #e6e6e6;
        border: 1px solid rgb(254, 254, 254);
        padding: 4px;
  }
    .CrossSellTable td:nth-child(1) {
        text-align: center;
        white-space: nowrap;
        width: 60%;
    }
    .CrossSellTable td:nth-child(2) {
        width: 20%;
    }
    .CrossSellTable td:nth-child(3) {
        width: 20%;
    }
    @media (max-width: 1600px) {
        .CrossSellTable {
            font-size: 0.78rem; 
            padding: 0px;/* Reducir el tamaño de fuente aquí */
        }
        .card-content{
            max-height: 200px;
        }
    }

    .p-hover:hover {

        cursor: pointer;
        text-decoration: underline;
    }
    .redhover{
        color: gray;
    }
    .redhover:hover{
        color: red;
        cursor: pointer;
    }
 