.containerSchedule{
    padding-bottom: 10px;
}

.message-expressionCron{
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-expressionCron{
    margin-left: 15px;
    margin-bottom: 5px;
}

/* New code */

