.error-details {
    margin: 20px 20px 20px 8px;
    padding: 20px;
    border: 1px solid #ff6666;
    border-radius: 5px;
    background-color: #fce5e5;
  }
  
  .message {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f3f3;
    border-left: 5px solid #ff3333;
  }
  .wrong-message {
    margin-bottom: 20px;
    padding: 10px;
    border-left: 2px solid #ff333338;
  }
  
  .source-object {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .h-errorRowRefresh {
    color: #cc0000;
  }
  
  .p-errorRowRefresh {
    margin: 5px 0;
  }
  /**/
  .success-details {
    margin: 20px 20px 20px 8px;
    padding: 20px;
    border: 1px solid #69ff66;
    border-radius: 5px;
    background-color: #e4fce2;
  }
  .warning-details {
    margin: 20px 20px 20px 8px;
    padding: 20px;
    border: 1px solid #ffcc66;
    border-radius: 5px;
    background-color: #fcf7e5;
  }
  
  .success-message {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f6fdf6;
    border-left: 5px solid #26bd30;
  }
  .warning-message {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fbfdf6;
    border-left: 5px solid #ff923338;
  }
  
  .h-successRowRefresh {
    color: #0fa314;
  }
  .h-warningRowRefresh{
    color: #988e00;
  }
  .item-descr-wrap{
    cursor: default;
  }
  .p-successRowRefresh {
    margin: 5px 0;
  }
  .buttonRefresh:hover {
    background-color: #f1f1f1;

  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .buttonGirador svg {
    animation: spin 2s linear infinite;
  }
  .rotated {
  transform: rotate(180deg);
  transition: transform 0.9s ease;
}
  .iconOpen{
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin-bottom: 7px;
  }