.containerinfoTicket {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ; /* El div derecho ocupa 250px de ancho */
    grid-template-rows: 140px 1fr 1fr 700px ;      /* Dos filas, cada una toma la mitad del alto disponible */
    height: auto;                    /* Altura total del viewport */
    gap: 10px;
    padding: 5px;
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-height: 600px) {
    .containerinfoTicket {
        height: auto;
       
    }
}
.contentinfoTicket p{
    margin-bottom: -1rem;
}

.div1infoTicket, .div2infoTicket {
    padding: 10px;                   /* Espaciado interno para los divs */
}

.div1infoTicket {
    grid-row: 1 / 2;                 /* Ocupa la primera fila */
    grid-column: 1 / 3;              /* Ocupa la primera columna */
    display: flex;
    flex-direction: column;   
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);       /* Contenidos en columna */
}
.div1infoTicket h4{
    color: rgb(109, 109, 109);
}

.div2infoTicket {
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    grid-row: 2 / 5;                 /* Ocupa la segunda fila */
    grid-column: 1 / 3;              /* Ocupa la primera columna */
}

.div3infoTicket {
    grid-row: 1 / 5;                 /* Ocupa ambas filas */
    grid-column: 3 / 4;              /* Ocupa la segunda columna */
    display: flex;
    flex-direction: column;          /* Contenidos en columna */
    background: #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.tableinfoTicket {
    border-collapse: collapse;       /* Para que las tablas no tengan márgenes entre celdas */
    width: 100%;                     /* Ocupa todo el ancho disponible */
    margin-bottom: 10px;             /* Espaciado entre las tablas */
}
.thinfoTicket, .tdinfoTicket {
    border: 1px solid rgb(0, 0, 0);         /* Bordes para las celdas de la tabla */
    text-align: left;
    padding: 8px;                    /* Espaciado interno en las celdas */
}
.contentinfoTicket{
    display: flex;
}
.tag-container {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 800px;
  }
  @media (max-width: 1800px) {
    .tag-container {
        width: 500px;
    }
}
  .tag {
    padding: 4px 16px;
    background: #FF6358;
    color: white;
    border-radius: 7px;
    font-size: 14px;
  }
  .containerTexts{
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
  }


  /* borrar */
  .k-card-body > .k-card-actions {
    margin-top: 20px;
}
.table-Ticket{
 padding: 20px;
 left: 20px;
}
.table-Ticket td {
    padding: 10px;  /* Ajusta este valor a tus necesidades */
    padding-left: 20px;
}
.ButtonEdit-ticket{
    background: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top:10px;
    visibility: hidden;
}
.ButtonEdit-ticket:hover{
    background: #f7d2d0;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
.div-Edit-ticket:hover{
    border:2px solid #f7d2d0;
    cursor: pointer;
}
.div-Edit-ticket:hover .ButtonEdit-ticket {
    visibility: visible;
}
.div-Edit-ticket{
    border:2px solid #ffffff;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width:100%;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
}
.ButtonBack{
    color: gray;
    margin-right: 15px;
    margin-top: -3px;
}
.ButtonBack:hover{
    color: #FF6358;
    cursor: pointer;
}
.ButtonBack:active {
    color: #8b352f;
}