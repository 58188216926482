.menuUnoDelete, .menuDosDelete {
    border-radius: 5px;
    padding: 15px;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.btncontmenuUnoDelete {
    display: flex;
    justify-content: center;
    gap:10px;
    margin-top: 10px;
}
.btncontmenuUnoDelete button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.spinner {
animation: spin 1s linear infinite;
}
.btnrojo:hover{
    background-color: #f44336;
    color: white;
}