.third-slice-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%; /* Ajusta el ancho según sea necesario */
  }
  
  .third-slice-grid-item {
    padding: 20px;
  }
  
  .third-slice-left {
    text-align: left;
  }
  
  .third-slice-right {
    text-align: right;
  }
  .third-slice-bold {
    font-weight: bold;
  }
  