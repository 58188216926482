.formulario-reporte {
    width: 70%;
    margin: 1rem auto;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .campo-formulario {
    margin-bottom: 15px;
  }
  
  .campo-formulario label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .campo-formulario input,
  .campo-formulario select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .container{
    display: flex;
    align-items: self-start;
  }
  .viewIcon{
    position: fixed;
    width: 80px; /* o puedes especificar un ancho en píxeles */
    height: 100px;
    top: 20rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .iconoDiv svg { 
    margin-top: 0.5rem;/* Esto afectará directamente al ícono si es un SVG */
  width: 80px;
  height: 80px;
}

