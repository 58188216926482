.Databrickscard {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 12px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* max-width: 400px; */
}
.Databrickscard h3 {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.Databrickscard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Databrickscard-body {
  margin-top: 10px;
}
.Databrickscard-body p {
  margin: 5px 0;
}

.Databrickscard-stats {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.Databrickscard-stats p {
  margin: 5px 0;
}

.successfull {
  color: green;
}

.failed {
  color: red;
}

.Databrickscard-stats.hidden {
  display: none;
}

.Databrickscard-stats.visible {
  display: block;
}
