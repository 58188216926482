
.GuestHomeApp {
  position: relative;
  margin-top: -10px;
  margin-left: -25px;
  margin-right: -15px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Main-section {
  position: relative;
  background: url('./woman-with-laptop-working-from-home-social-distancing.jpg') no-repeat center center/cover;
  height: 95.9vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  margin: -10px -15px 0px -25px;
}

.Main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,#00244C, #414f5f);
  opacity: 0.7;
  z-index: 1;
}

.Main-section .GuestHomeApp-content {
  text-align: left;
  z-index: 3;
  position: absolute;
  top: 20%;
  left: 5%;
  color: white;
}

.browse-btn {
  background-color: orange;
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}

.Contact-section {
  background: linear-gradient(to bottom, rgba(234, 234, 234, 0) 0%, rgb(0, 0, 0) 60%);
  color: white;
  width: 102.14%;
  position: absolute;
  bottom: -3%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.5s ease, padding-bottom 0.5s ease;
}

.contact-box {
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  
  .register-btn {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }


  .Card-section-guest {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    padding-top: 61px;
    width: 200%;
}

.card-container-guest {
    display: flex;
    justify-content: space-around;
    width: 80%;
}

.card-guest {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 28%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}