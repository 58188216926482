.EmployeeNod {
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px;
    width: 263px;
    border-radius: 8px;
}
@media (max-width: 1300px) {
    .EmployeeNod {
        width: 193px;   
    }
}
.Organigram{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 700px;
}
.column-cards{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}
