.boxShadow {
    border: 1px solid var(--siigo-blue) !important;
    border-radius: 5px !important;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 16px 8px !important;
    margin: 3px !important;
    margin-top: 15px;
}

.paddingTop {
    padding-top: 3px !important;
    margin: auto;
}

.tableColumn {
    font-size: 10px;
    padding: 1px;
}


.k-tabstrip-items>.k-item>.k-link {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans';
    color: black;
}