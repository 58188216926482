.iputSkillsDataList {
	border: solid #00244c 1px;
	width: calc(100% - 20px);
	padding: 5px 0 5px 10px;
	border-radius: 8px;
}

.saveIconContainer {
	float: right;
	width: 20px;
	padding-top: 3px;
	position: relative;
}

.siignal-update-btn {
	padding: 16px;
	margin: 16px;
	color: #00244c;
	border: solid 1px var(--siigo-blue);
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
	margin-left: '0';
}