.bar-container {
    width: 93%;
    height: 59px;
    margin: 20px auto; /* Ajusta los márgenes superior e inferior a 20px y establece los márgenes izquierdo y derecho a auto */
    position: relative;
  }
  
  .bar {
    position: relative;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(to right, #1C891A 50%, #ffbf00 76%, #ff0000 100%);
  }
  
  .marker {
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    font-size: 12px;
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .min {
    color: green;
  }
  
  .avg {
    color: blue;
  }
  
  .max {
    color: red;
  }
  
  .bar-labels {
    position: relative;
    margin-top: 10px;
  }
  
  .bar-label {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  