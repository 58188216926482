.CardShowRowUpd {

    border-radius: 4px;

    transition: box-shadow 0.3s ease;
  }
  
  .CardShowRowUpd-header {
    margin-bottom: 12px;
  }
  
  .CardShowRowUpd-body {
    margin-bottom: 12px;
  }
  
  .CardShowRowUpd-footer {
    font-size: 0.9em;
    color: #777;
  }
  

/* //////////////////// */
.ControlCenterGrid {
    display: flex;
    flex-direction: row;
    transition: width 0.3s ease;
    align-items: flex-start;
}

.childrenControlCenter1 {
    flex: 3;
    margin-left: 10px;
    display: flex;
    min-width: '400px';
    justify-content: flex-end;
    align-items: flex-start; 
  
}

.childrenControlCenter2 {
    flex: 7; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: flex-start;
}