.Navbar {
	position: fixed;
	top: 0;
	left: 0;
	margin-top: 41px;
	background-color: #f5f5f5;
	width: 270px;
	height: 100%;
	box-sizing: border-box;
	transition: width 0.2s ease-in;
	z-index: 100;
	overflow-y: auto;
}

.Navbar.inactive {
	width: 75px;
}

.Navbar.inactive .top-section .toggle-menu-btn {
	max-width: 0%;
	max-height: 0%;
}
.bottom-section {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1;
}
@keyframes rotate180again {
	from {
		transform: rotate(180deg);
	}
	to {
		transform: rotate(0deg);
	}
}

.Navbar.inactive .top-section .logo {
	display: inline-block;
	color: #000000;
	height: 30px;
	width: 30px;
	line-height: 40px;
	text-align: center;
	margin: 5px 5px;
	box-sizing: content-box !important;
	animation: rotate180again 0.3s linear forwards;
}

.Navbar.inactive .main-menu-ds {
	margin: 20px 0;
}

.Navbar.inactive .main-menu-ds ul {
	margin: 0;
	padding: 0;
}
.Navbar.inactive .main-menu-ds li {
	list-style: none;
}

.Navbar.inactive .main-menu-ds .item-icon {
	display: flex;
	color: #000000;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	font-size: 25px;
	line-height: 40px;
	text-align: center;
	margin-left: 3px;
}

.Navbar.inactive .main-menu-ds .menu-item span {
	opacity: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}
.Navbar.inactive .main-menu-ds .menu-item {
	display: flex;
	width: 75px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 10px 0;
}
.Navbar.inactive .main-menu-ds .menu-item:hover {
	background-color: #e1e1e1;
}
.menu-item.selected {
	background-color: #e1e1e1; /* Cambia el fondo del elemento seleccionado */
	border-radius: 5px; /* Añade un borde redondeado */
}
.Navbar.inactive .main-menu-ds .menu-item p {
	font-size: 12px;
	margin: 0;
	opacity: 0.7;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 46px; /* Ancho máximo del texto */
	text-align: center; /* Justificación centrada */
	word-wrap: break-word; /* Permite que el texto se ajuste dentro del ancho máximo */
}

.Navbar .top-section {
	position: relative;
}

@keyframes rotate180 {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}
.Navbar .top-section .logo {
	display: inline-block;
	color: #000000;
	height: 30px;
	width: 30px;
	line-height: 40px;
	text-align: center;
	margin: 5px 10px;
	box-sizing: content-box !important;
	animation: rotate180 0.3s linear forwards;
}
.Navbar .top-section .logo img {
	max-width: 100%;
	max-height: 100%;
}

.Navbar .top-section .toggle-menu-btn {
	color: #ffffff;
	font-size: 25px;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.Navbar .main-menu-ds {
	margin: 5px 5px;
	cursor: pointer;
}

.Navbar .main-menu-ds .item-icon {
	display: inline-block;
	color: #000000;
	height: 40px;
	width: 40px;
	font-size: 25px;
	line-height: 60px;
	text-align: center;
	margin: 5px 0;
}

.Navbar .main-menu-ds .menu-item span {
	color: #ffffff;
	font-weight: 700;
	position: absolute;
	text-decoration: none;
	display: inline-block;
	line-height: 60px;
	opacity: 1;
	transition: opacity 0.2s ease-in;
	font-size: 17px;
	padding-left: 5px;
	padding-top: 2px;
}

.Navbar .main-menu-ds .sub-menu-ds {
	color: #ffffff;
	margin-left: 20px;
	border-left: 1px solid;
	box-sizing: border-box;
	padding-left: 30px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.01s ease-in;
}

.Navbar .main-menu-ds .sub-menu-ds.active {
	max-height: 200px;
}

.Navbar .main-menu-ds .sub-menu-ds {
	display: block;
	margin: 5px 0;
	font-size: 15px;
	font-weight: 600;
}

.container-top-navbar {
	box-sizing: content-box !important;
}

.container-top-navbar-right .top-navbar-item .icon-item {
	box-sizing: content-box !important;
}
