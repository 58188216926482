.contenedor-helpDesk{
    background-color: rgb(255, 255, 255);
    width: 100%; 
    height: 100%;
}

@media (max-width: 768px) {
    .contenedor-helpDesk{
        display: flex;
        flex-direction: column;
    }
    .contenedor-helpDesk-child {
        width: 100%;
    }
}

.helpdesk_image {
    flex-direction: column;
    justify-content: center;
}

.rayitaa{
    width: 80%;
    margin:auto;
    height: 0.1rem;
    background-color: #8f8f8f;
}
.helpDesk-botones{
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px; 
    padding: 10px;
}

.helpDesk-tarjetas{
    margin: auto;
    width: 80%;
}
.helpdesk_centrar h1{
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #00244c;
    font-size: 80px;
}
.helpdesk_centrar h3{
    color: #00244c;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}
.helpdesk_centrar p{
    font-family: 'Nunito', sans-serif;
    color: #00244c;
    font-size: 20px;
    margin-top: 1rem;
}
@media (max-width: 1350px) {
    .helpdesk_centrar h1{
        font-size: 80px;
    }
    .helpdesk_centrar h3{
        font-size: 20px;
    }
    .helpdesk_centrar p{
        font-size: 15px;
        width: 350px;
    }
}