:root {
	--siigo-blue: #00aaff;
	--siigo-green: #7ac23a;
	--siigo-orange: #ffa532;
	--siigo-yellow: #ffc933;
	--siigo-red: #f7274e;
}


.massiveIncidents input,
.massiveIncidents textarea,
.massiveIncidents select,
.massiveIncidentsUserForm {
	border: solid 1px var(--siigo-blue);
	padding: 3px;
	border-radius: 3px;
	width: 100%;
}

.massiveIncidentsTextArea {
	height: 150px;
}

.WhatsAppConnect {
	display: grid;
	grid-gap: 10px;
}

.WhatsAppConnect-cardDetails {
	font-size: 12px;
}

.WhatsAppConnect-messagesList {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 1fr)
}

.WhatsAppConnect-textarea {
	height: 250px;
	padding: 8px;
	border-radius: 5px;
}

.WhatsAppConnect-cardButton {
	float: right;
}

.WhatsAppConnect-cardHeaderAction {
	border: solid gray 1px;
	padding: 5px;
	float: right;
	border-radius: 5px;
	margin-left: 8px;
}

@media (max-width: 1200px) {
	.WhatsAppConnect-messagesList {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 800px) {
	.WhatsAppConnect-messagesList {
		grid-template-columns: 1fr;
	}
}

.WhatsAppConnect-step2 {
	border: solid var(--siigo-blue) 1px;
	border-radius: 5px;
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	gap: 8px;
}

.whatsAppConnect-templateInputs {
	width: 100%;
}

.whatsAppConnect-templateInputs th,
.whatsAppConnect-templateInputs td {
	border-collapse: collapse;
	border: solid var(--siigo-blue) 1px;
	padding: 4px;
}

.whatsAppConnect-templateInputs input,
.WhatsAppConnect-templateDetails .gridCell input {
	width: 100%;
	border: solid gray 1px;
	padding: 5px;
}

.whatsappConnectParameterRendered {
	border: solid var(--siigo-orange) 1px;
	padding: 2px;
}


.whatsapp-message-green {
	background-color: #DCF8C6;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	word-wrap: break-word;
	box-shadow: 2px 2px 5px #888888;
}

.whatsapp-message-white {
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	word-wrap: break-word;
	box-shadow: 2px 2px 5px #888888;
}

.WhatsAppConnect-templateDetails {
	display: grid;
	grid-template-columns: repeat(20, 1fr);
	gap: 10px;
}

.WhatsAppConnect-templateDetails .gridCell {
	border: solid var(--siigo-blue) 1px;
	padding: 5px;
	border-radius: 5px;
	grid-column: span 4;
}

.WhatsAppConnect-templateDetails .gridCell:nth-child(n+6) {
	grid-column: span 5;
}

.WhatsAppConnect-templateDetails .gridCell:nth-child(10n) {
	grid-column: span 20;
}

.WhatsAppConnect-templateDetails .gridCell:nth-child(11n),
.WhatsAppConnect-templateDetails .gridCell:nth-child(13n) {
	border: none;
	grid-column: span 7;
}

.WhatsAppConnect-templateDetails .gridCell:nth-child(12n) {
	border: none;
	grid-column: span 6;
}

.WhatsAppConnect-warning {
	background: var(--siigo-orange);
}

.WhatsAppConnect-success {
	background: var(--siigo-green);
}

.whatsAppconnect {
	border-collapse: collapse;
	width: 100%;
}

.whatsAppconnect td,
.whatsAppconnect th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

.whatsAppconnect th {
	background-color: #f2f2f2;
}

.whatsAppconnect .pointer {
	cursor: pointer;
}

.whatsAppconnect .paddingBTN {
	margin-left: 5px;
	display: inline-block;
}

.whatsAppconnect-cursorPointer {
	cursor: pointer;
}