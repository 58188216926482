.iputSkillsDataList {
	border: solid #00244c 1px;
	width: calc(100% - 20px);
	padding: 5px 0 5px 10px;
	border-radius: 8px;
}

.saveIconContainer {
	float: right;
	width: 20px;
	padding-top: 3px;
	position: relative;
}
.skillToCell-modulePresentation {
	margin: auto !important;
	margin-bottom: 3px !important;
	font-size: 18px !important;
	font-weight: 'bold' !important;
}
.skillToCell-box-shadow {
    width: calc(100%);
    border: 1px solid #00244c;
    padding: 8px 3px 8px 8px !important;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}
		