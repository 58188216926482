@font-face {
	font-family: 'Nunito ExtraLight';
	src: url('./font/Nunito-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Light';
	src: url('./font/Nunito-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Regular';
	src: url('./font/Nunito-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Medium';
	src: url('./font/Nunito-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito SemiBold';
	src: url('./font/Nunito-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Bold';
	src: url('./font/Nunito-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito ExtraBold';
	src: url('./font/Nunito-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Black';
	src: url('./font/Nunito-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito ExtraLight';
	src: url('./font/Nunito-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Light';
	src: url('./font/Nunito-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Regular';
	src: url('./font/Nunito-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Medium';
	src: url('./font/Nunito-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito SemiBold';
	src: url('./font/Nunito-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Bold';
	src: url('./font/Nunito-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito ExtraBold';
	src: url('./font/Nunito-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito Black';
	src: url('./font/Nunito-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

.editorContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
}

.editor {
	width: 100%;
	margin: auto;
	font-family: 'Nunito Regular';
	font-size: 12px;
}

.fullWidth {
	width: 100%;
}

/*
    Use the DejaVu Sans font for displaying and embedding in the PDF file.
    The standard PDF fonts do not support Unicode characters.
*/
.PDFExport {
	margin: auto;
	height: 75vh;
	overflow-y: scroll;
}
.Editor {
	border: solid red 1px;
	margin-top: 10px;
	padding: 5px;
}
/*
    The example loads the DejaVu Sans from the Kendo UI CDN.
    Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is
    https://dejavu-fonts.github.io/.
*/
@font-face {
	font-family: 'DejaVu Sans';
	src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf')
		format('truetype');
}

@font-face {
	font-family: 'DejaVu Sans';
	font-weight: bold;
	src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf')
		format('truetype');
}

@font-face {
	font-family: 'DejaVu Sans';
	font-style: italic;
	src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
		format('truetype');
}

@font-face {
	font-family: 'DejaVu Sans';
	font-weight: bold;
	font-style: italic;
	src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
		format('truetype');
}
