.status-card {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    margin-bottom: 10px;
    box-sizing: content-box !important;
}

.status-card__icon {
    width: 30%;
    height: 100%;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-sizing: content-box !important;
}

.status-card__info {
    flex-grow: 1;
    text-align: center;
    z-index: 1;
    text-transform: capitalize;
    box-sizing: content-box !important;
}

.status-card-DS {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    margin-bottom: 10px;
    box-sizing: content-box !important;
}

.status-card__icon-DS {
    width: 30%;
    height: 100%;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-sizing: content-box !important;
}

.status-card__info-DS {
    flex-grow: 1;
    text-align: center;
    z-index: 1;
    text-transform: capitalize;
    box-sizing: content-box !important;
}

.status-card-DS:hover {
    color: var(--txt-white);
}
.status-card-DS, .status-card__icon-DS {
    align-items: center;
    display: flex;
    z-index: 1;
}

.status-card__info > h4 {
    font-size: 30px;
    margin-bottom: 10px;
}

.status-card::before {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
        to top right,
        var(--main-color),
        var(--second-color)
    );
    position: absolute;
    left: -50%;
    top: 0;
    transform: scale(0);
    transition: transform 0.8s ease 0s;
}

.status-card:hover::before {
    transform: scale(3);
}

.status-card:hover {
    cursor: pointer;
    color: var(--txt-white);
}
.status-card__info p{

    margin-bottom: 0px;

}
.status-card-editado {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s, transform 0.2s;
    margin-bottom: 10px;
    box-sizing: content-box !important;
    position: relative;
    animation: rotate 0.28s infinite linear;
}
.status-card__icon-editado {
    width: 30%;
    height: 100%;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-sizing: content-box !important;
}

.status-card__info-editado {
    flex-grow: 1;
    text-align: center;
    z-index: 1;
    text-transform: capitalize;
    box-sizing: content-box !important;
}

.status-card-DS-editado {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease 0s;
    margin-bottom: 10px;
    box-sizing: content-box !important;
}

.status-card__icon-DS-editado {
    width: 30%;
    height: 100%;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-sizing: content-box !important;
}

.status-card__info-DS-editado {
    flex-grow: 1;
    text-align: center;
    z-index: 1;
    text-transform: capitalize;
    box-sizing: content-box !important;
}

.status-card-DS-editado:hover {
    color: var(--txt-white);
}
.status-card-DS-editado, .status-card__icon-DS-editado {
    align-items: center;
    display: flex;
    z-index: 1;
}

.status-card__info-editado > h4 {
    font-size: 30px;
    margin-bottom: 10px;
}

.status-card-editado::before {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
        to bottom left,
        #00244C,
        #00244C
    );
    position: absolute;
    left: -50%;
    top: 0;
    transform: scale(0);
    transition: transform 0.8s ease 0s;
}

.status-card-editado:hover::before {
    transform: scale(3);
}

.status-card-editado:hover {
    cursor: pointer;
    color: var(--txt-white);
}
.status-card__info-editado p{

    margin-bottom: 0px;

}