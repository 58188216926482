.container-activities {
    overflow-y: auto;
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
}

.card-activities {
    background-color: #FBFBFB;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card-activities:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #ededed;
    cursor: pointer;
}

.card-activities h2, .card-activities h3 {
    color: #333;
}

.card-activities p {
    color: #666;
    line-height: 1.6;
}

/* Asegura que el contenedor tenga suficiente altura para hacer scroll */
.container-activities {
    max-height: 400px; /* Ajustar según sea necesario */
}
.Button-circle{
    width: 70px;
    height: 70px;
    border-radius: 50%  ;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #FF6358;
    color: white;
    font-size: 20px;
    cursor: pointer;
    
}
.Button-circle:hover{
    background: #d2d2d2;
    color: #FF6358;
}   