
.contenedor-dowload-ticket {
    width: 200px;
    height: 80px;
    background: #DADADA;
    border-radius: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.dissapear-text {
    display: none;
  }
.messageWaitingDownload{
    animation: titling 1.6s infinite;
}

@keyframes titling {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.panelsDescription {
    background: #f6f7ff;
    border-radius: 14px;
    padding: 10px;
}

