.contenedorTarjet {
    overflow-x: auto;
}

.DivContTarjet{
  height: max-content;
}

/* MIG 1-OCT*/
.NewRequest{ 
  padding-left: 1rem;
}

.ActivityCard{
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 10px 10px 0 0;
}


