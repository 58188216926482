:root {
    --siigo-blue: #00aaff;
    --siigo-green: #7ac23a;
    --siigo-orange: #ffa532;
    --siigo-yellow: #ffc933;
    --siigo-red: #f7274e;
}


.renewalsMX-SerialsList {
    width: 100%;
}

.renewalsMX-SerialsList th {
    background: #d3d3d3;
    font-size: 1.2em;
}

.renewalsMX-SerialsList .success {
    background: #9cca73;
    font-weight: 600;
}

.renewalsMX-SerialsList .danger {
    background: #e598a6;
    font-weight: 600;
}

.renewalsMX-SerialsList th {
    border-collapse: collapse;
    border: solid 1px var(--siigo-blue);
    padding: 3px 8px;
}

.renewalsMX-SerialsList td {
    border-collapse: collapse;
    border: solid 1px var(--siigo-blue);
    padding: 2px 5px;
}

.renewalsMX-SerialsList-input {
    border: solid 1px #d3d3d3;
    width: 100%;
}

.renewalsMX-SerialsList-generalInputs div {
    border: solid var(--siigo-blue) 1px;
    padding: 10px 15px;
    border-radius: 5px;
}

.renewalsMX-SerialsList-generalInputs input,
.renewalsMX-SerialsList-generalInputs select {
    border: solid #d3d3d3 1px;
    padding: 3px 8px;
    width: 100%;
    border-radius: 5px;
}

.renewalsMX-mt {
    margin-top: 10px;
}

.siigoRenewalsCenterText {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding: 20px;
}

.siignalCenteredText {
    text-align: center;
}