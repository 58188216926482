.custom-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: center;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.custom-card:hover {
    transform: translateY(-5px);
    background-color: rgb(244, 244, 244);
    cursor: pointer;
}
.custom-card:active {
    transform: scale(0.95);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

.card-logo img {
    max-width: 100px;
    height: 40px;
    margin-bottom: 15px;
}

.card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
}

.card-description {
    font-size: 14px;
    color: #484a4b;
}
