.ShiftNoveltiesSchedulesContainer .k-scheduler {
	height: calc(100vh - 100px);
	width: auto;
}
pre {
	font-size: 0.8rem;
}
.svgCont {
	height: 4em;
}
table.shiftNovelties {
	border-collapse: collapse;
	width: 100%;
}
table.shiftNovelties th,
table.shiftNovelties td {
	border: 1px solid #ccc;
	padding: 0 10px 0 10px;
  /* max-width: 350px; */
	/* margin: 5px; */
	/* white-space: nowrap; */
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */
}
table.shiftNovelties th {
	text-align: center;
  font-size: 1.2rem;
}
table.shiftNovelties tr:nth-child(even) {
	background-color: #f2f2f2;
	color: black;
}

table.shiftNovelties tr:hover {
	background-color: #f2f2f2;
	color: black;
}

table.shiftNovelties td {
	text-align: center;
}
