.cardContent {
	margin: 5px;
	padding-bottom: 16px !important;
	box-sizing: border-box;
	border: 1px solid #00244c;
	border-radius: 5px;
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.personal-card-title {
	margin-bottom: 3px !important;
	font-size: 20px !important;
	font-weight: 'bold' !important;
}

.personal-card-value {
	margin-bottom: 3px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
}

.paddingTop {
	padding-top: 15px;
	margin: auto;
}

.centerContentHorizontal {
	display: contents;
	align-items: center;
}

.bigNumber {
	font-size: 30px !important;
	font-weight: 'bold' !important;
}

.label {
	font-size: 16px !important;
	font-weight: 600 !important;
}

.paddingTop {
	padding-top: 15px;
	margin: auto;
}

.personal-box-shadow {
    width: calc(100%);
    border: 1px solid #00244c;
    padding: 8px 3px 8px 8px !important;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.centerH {
	margin: auto;
}

.Actualizar {
	background-color: #00244c;
	color: white;
	border: #00244c solid 2px;
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
	width: 100%;
}

.lastUpdaced-centered {
	margin: auto;
	font-weight: 600;
	font-size: 15px;
}

.nextUpdate-centered {
	width: 250px;
	margin: auto !important;
	margin-top: 10px !important;
	font-weight: 600;
	font-size: 15px;
}
.chartCont {
	margin: 0px auto;
    width: auto;
    position: relative;
    min-height: calc(100vh - 330px);
}
.nouserDataFound {	
	margin: auto !important;
	margin-bottom: 3px !important;
	font-size: 16px !important;
	font-weight: 'bold' !important;
	color: rgb(195, 0, 0)
}