:root {
    --siigo-blue: #00aaff;
    --siigo-green: #7ac23a;
    --siigo-orange: #ffa532;
    --siigo-yellow: #ffc933;
    --siigo-red: #f7274e;
}

.siignal-landing-header {
    background-color: #00244C;
    color: #fff;
    text-align: center;
    padding: 60px 0;
    width: 100%;
}

.siignal-landing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
}

.siignal-landing-header p {
    font-size: 1.2rem;
}

.siignal-landing-header span {
    font-size: 1rem;
}

.siignal-colombia-flag,
.siignal-ecuador-flag {
    background: linear-gradient(45deg, #ffdd0050 33.33%, #0033a050 33.33%, #0033a050 66.66%, #ce112650 66.66%);
}

.siignal-mexico-flag {
    background: linear-gradient(45deg, #00684750 33.33%, #ffffff50 33.33%, #ffffff50 66.66%, #e2001a50 66.66%);
}

.siignal-uruguay-flag {
    background: linear-gradient(45deg, #ffffff50 11.11%, #0033a050 11.11%, #0033a050 22.22%, #ffffff50 22.22%, #ffffff50 33.33%, #0033a050 33.33%, #0033a050 44.44%, #ffffff50 44.44%, #ffffff50 55.55%, #0033a050 55.55%, #0033a050 66.66%, #ffffff50 66.66%, #ffffff50 77.77%, #0033a050 77.77%, #0033a050 88.88%, #ffffff50 88.88%, #ffffff50 100%);
}

.siignal-box-shadow {
    width: calc(100%);
    border: 1px solid var(--siigo-blue);
    padding: 8px 8px 8px 8px !important;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.siignal-landing-shadow {
    width: calc(100%);
    border: 1px solid #00244c;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 12px;
}

.siignal-w100 {
    width: 100%;
}

.siignal-title {
    font-size: 18px;
}

.siignal-version {
    color: #9e9e9e;
    float: right;
}