.notification-card {
	width: 300px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 0px 7px rgba(0, 0, 0, 0.3);
	padding: 16px;
	margin: 10px;
	font-family: Arial, sans-serif;
	color: #333;
	position: relative;
}

.notification-card-card-header {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	justify-content: space-between;
}

.notification-card-icon-circle {
	flex-shrink: 0;
	width: 28px;
	height: 28px;
	margin-right: 9px;
	margin-left: -2px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notification-card-title {
	font-weight: bold;
	font-size: 16px;
	color: #333;
	flex-grow: 1;
}

.notification-card-close-button {
	flex-shrink: 0;
	background: none;
	border: none;
	width: 18px;
	height: 38px;
	font-size: 16px;
	color: #aaa;
	cursor: pointer;
}

.notification-card-close-button:hover {
	color: #292929;
}

.notification-card-card-body {
	margin-bottom: 16px;
}

.notification-card-message {
	font-size: 14px;
	color: #757575;
	line-height: 1.5;
}

.notification-card-action-button {
	color: #676767; /* Color del texto similar a un enlace */
	font-size: 11px;
	cursor: pointer;
	text-decoration: none;
}

.notification-card-action-button:hover {
	color: #0056b3; /* Color del texto al pasar el ratón por encima */
	text-decoration: underline;
}
