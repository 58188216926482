.rayita{
    width: 70rem;
    height: 2px;
    background: #e0e0e0;
}
@media (max-width: 1700px) {
    .rayita{
        width: 60rem;
        height: 2px;
        background: #e0e0e0;
    }
}
@media (max-width: 1600px) {
    .rayita{
        width: 50rem;
        height: 2px;
        background: #e0e0e0;
    }
}
@media (max-width: 1500px) {
    .rayita{
        width: 40rem;
        height: 2px;
        background: #e0e0e0;
    }
}
.caja-cajita-comment{

    overflow-y: scroll;
    max-height: 300px;
}

.cajita-comment{
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border: 1.6px solid #d8d8d8;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    
}
.comment-apear{

    background: #f9f9f9;

    opacity: 1;
}