.container-home-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 320px;
    grid-template-rows: 140px 1fr 1fr 80%;
    height: auto;
    gap: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1600px) {
    .container-home-wrap {
        grid-template-columns: 1fr 1fr 220px ; 
        grid-template-rows: 120px 1fr 1fr 500px ;    
    }

}
@media (max-height: 600px) {
    .containerinfoTicket {
        height: auto;
       
    }
}

.div1Wrap, .div2Wrap {   
    border-radius: 13px;               /* Espaciado interno para los divs */
}

.div1Wrap {
    grid-row: 1 ;                 /* Ocupa la primera fila */
    grid-column: 1 / 4;              /* Ocupa la primera columna */
    margin : 16px 16px 0px 16px;
    z-index: 1;
    display: flex;
    flex-direction: column;   
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);      
}
.div1Wrap h4{
    color: rgb(109, 109, 109);
}

.div2Wrap {
    background: #ffffff;
    /*box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);*/
    grid-row: 2 / 5;                 /* Ocupa la segunda fila */
    grid-column: 1 / 3;              /* Ocupa la primera columna */
    margin: -11px 0px 0px 0px;      /* Espaciado interno */
}

.div3Wrap {
    grid-row: 2 / 5;
    grid-column: 3 / 4;
    display: flex;
    /* padding-top: 20px; */
    flex-direction: column;
    margin: 5px 16px 0px -11px;
    background: #ffffff;
    border-radius: 9px;
    /* box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.08); */
    height: 0px;
}
@media screen and (max-width: 1300px) {
    .div1Wrap {
        margin: 7px 7px 0px 14px;      /* Espaciado interno */
    }
    .div3Wrap {
        margin: 5px 7px 0px -16px;      /* Espaciado interno */
    }
    .div2Wrap {
        margin: -11px 0px 0px 0px;      /* Espaciado interno */
    }
    
}