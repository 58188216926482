.example-wrapper {
    margin: 0 auto;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 5px;
}
.page { padding: 20px 10px; }
.content {
    background: #F9F9F9;
    height: auto;
    overflow: auto;
}
.box { 
    padding: 4px;
    background-color: #999999;
    height: auto;
}
.tnb-container{
    padding: 10px;
    background: #F9F9F9;
    border-radius: 7px;
    height: auto;
    overflow: auto;
}
.panels-tnb{
    padding: 10px;

    border-radius: 7px;
    height: auto;
    overflow: auto;
}
.panels-tnb h6{
    margin-bottom: 20px;
}
.fieldFormTnb{
    width: 210px;
    margin-right: 10px;
}
@media (max-width: 1300px) {
    .fieldFormTnb{
        width: 70px;
        margin-right: 5px;
    }
}
.small-container{

}
