.containerSecondaryDisplay{
    position: relative;
    z-index: 1;
}

.centerChunk{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
}