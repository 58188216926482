.tab {
  width: 100%;
  padding: 25px;
  color: #000000;
}


.tabs {
display: inline;
}

.tabs ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.tabs ul li {
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all 0.3s;
  font-weight: 300;
  font-size: 12px;
  cursor: pointer;
  color: #aaa;
  margin-right: 5px;
}
.tabs ul li:hover {
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all 0.3s;
  font-weight: 300;
  font-size: 12px;
  cursor: pointer;
  color: #000000;
  margin-right: 5px;
}

.tabs ul li.selected {
  border-bottom: 2px solid #337ab7;
  color: #000000;
  font-weight: 300;
}
.tabs ul li.Agregar{
  left: 10rem;
  margin-left: 5rem;
  border: 1px solid #777777;
  border-radius: 25rem;
  color: #808080;
  font-weight: 400;
}
.tabs ul li.Agregar:hover{
  background-color: #36487a;
  border: 1px solid #777777;
  color: #ffffff;
  font-weight: 400;
  
}
.tabs span{
  color: #161616;
}
.tabs ul li:hover span{
  color: #ffffff;
}
