:root {
	--siigo-blue: #00aaff;
	--siigo-green: #7ac23a;
	--siigo-orange: #ffa532;
	--siigo-yellow: #ffc933;
	--siigo-red: #f7274e;
}


.massiveIncidents input,
.massiveIncidents textarea,
.massiveIncidents select,
.massiveIncidentsUserForm {
	border: solid 1px var(--siigo-blue);
	padding: 3px;
	border-radius: 3px;
	width: 100%;
}

.massiveIncidentsTextArea {
	height: 150px;
}

.massiveIncidents {
	display: grid;
	grid-gap: 10px;
}

/* Estilo para cada elemento de incidente */
.incident-item {
	display: flex;
	flex-direction: column;
}

/* Estilo para el encabezado del incidente (código) */
.incident-header-Abierto {
	background-color: var(--siigo-red);
	/* Color de fondo del encabezado */
	color: #fff;
	/* Color del texto del encabezado */
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

/* Estilo para el encabezado del incidente (código) */
.incident-header-Cerrado {
	background-color: var(--siigo-green);
	/* Color de fondo del encabezado */
	color: #fff;
	/* Color del texto del encabezado */
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

/* Estilo para la descripción del incidente */
.incident-description {
	margin: 10px 0;
}

/* Estilo para las fechas y el estado en la esquina superior derecha */
.massiveIncidents__dates {
	margin-top: auto;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

/* Estilo para cada p dentro de las fechas y el estado */
.massiveIncidents__dates p {
	margin: 0;
	margin-left: 10px;
	/* Espacio entre elementos de fecha y estado */
}

.listIncidents {
	margin-top: 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
}

.formAddUSerToList {
	display: grid;
	gap: 10px;
	border: solid 1px var(--siigo-blue);
	padding: 10px;
	border-radius: 5px;
}

.formAddUSerToList input {
	margin: auto;
	margin-right: 10px;
}

.massiveIncidentsRightTitle {
	position: absolute;
	right: 10px;
}

.massiveIncidentsCardActive {
	border: solid var(--siigo-red) 1px;
	grid-area: auto / auto / auto / auto;
	border-radius: 5px;
}

.massiveIncidentsCardClosed {
	border: solid var(--siigo-green) 1px;
	grid-area: auto / auto / auto / auto;
	border-radius: 5px;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f0f0f0;
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
}

.paginationButton {
	padding: 5px 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.paginationButton:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.paginationButton:hover {
	background-color: #0056b3;
}

.paginationDetails {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 10px;
}