textarea {
    width: 100%;
    height: 100px;
}

.tablero-input,
.tablero-button {
    width: 100%;
    padding: 8px;
}

.centered {
    /* border: solid rgb(200, 200, 200) 1px; */
    width: 100%;
    padding: 6px;
}

.detalles {
    height: 300px;
}

.labelInputCustom {
    background: white;
    padding-right: 5px;
    padding-left: 5px;
    font-weight: 700;
}

.AlertText input {
    color: red !important;
    text-align: center !important;
}

.notaCreditoTitle {
    text-align: center;
}

.renewalsAgentPanelInput {
    width: 100%;
    border: solid #4c4c4c 1px;
    border-radius: 4px;
}

.savingPopUp {
    position: fixed;
    top: 50%;
    z-index: 99999999;
    right: calc(50vw - 350px);
    height: 80px;
    width: 600px;
    text-align: center;
    padding-top: 32px;
    font-size: 1em;
    border: solid var(--siigo-blue) 1px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}