/* General message styles */
.message-cb {
    display: flex;
    margin: 10px 0;
    word-wrap: break-word;
}
.circle-left-cb, .circle-right-cb {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
    font-size: 24px;
}

.circle-left-cb {
    margin-right: 10px; 
    background-color: #00244c;
}

.circle-right-cb {
    margin-left: 10px; 
    background-color: #696969;
}

.message-cb.user-cb {
    justify-content: flex-end;
}

.message-cb.bot-cb {
    justify-content: flex-start;
}

.message-cb.user-cb .message-content-cb {
    color: #353535;
    border-top-right-radius: 0;
    border: 1px solid #00244c;
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
}
.message-cb.user-cb .message-content-cb p {
    margin: 0; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
}
.message-cb.bot-cb .message-content-cb {
    background-color: #e1e1e1;
    color: black;
    border-top-left-radius: 0;

}
/* .message-cb.bot-cb .message-content-cb p{
    margin: 0; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
} */

.message-content-cb {
    max-width: 80%;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
}

/* Clear chat button styles */
.clear-chat-button-cb {
    background: none;
    border: none;
    color: #00244c;
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
}

.clear-chat-button-cb:hover {
    color: #aeaeae;
}

/* Chat box container styles */
.chat-box-container-cb {
    position: fixed;
    bottom: 20px;
    right: 104px;
    width: 600px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    font-family: 'Nunito', sans-serif;
    z-index: 9999;
}

.chat-logo-cb {
    width: 32px; 
    height: 25px;
    margin-right: 10px;
}

/* Chat container styles */
.chat-container-cb {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.chat-container-cb.open-cb .chat-box-container-cb {
    width: 60%;
    transition: width 0.3s;
}

.chat-container-cb.closed-cb .chat-box-container-cb {
    width: 30%;
    transition: width 0.3s;
}

.chat-box-container-cb.with-references-cb {
    width: 60%;
}

/* Chat content styles */
.chat-content-cb {
    display: flex;
    flex-direction: row;
    height: 70vh;
}

.chat-box-cb {
    display: flex;
    flex-direction: column;
    flex: 3 1;
}

.messages-cb {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.references-toggle-cb {
    background-color: #007bff;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}

/* References styles */
.references-cb {
    flex: 2;
    padding: 10px;
    border-left: 1px solid #ddd;
    background-color: #f9f9f9;
    font-size: 14px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    word-wrap: break-word;
}

.reference-group-wrapper-cb {
    margin-bottom: 5px;
}

.reference-group-cb {
    background: #f1f1f1;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.reference-cb {
    margin-top: 5px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
}

.reference-content-cb {
    display: none;
}

.reference-content-cb.expanded-cb {
    display: block;
}

.reference-button-cb {
    background-color: #f1f1f1;
    border: 1px solid #007bff;
    color: #007bff;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    word-wrap: break-word;
}

.reference-button-cb:hover {
    background-color: #e1e1e1;
}

.question-toggle-button-cb {
    background-color: #00244c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.question-toggle-button-cb:hover {
    background-color: #00244c;
}

.question-toggle-button-cb.expanded-cb {
    background-color: #00244c;
}

.reference-title-button-cb {
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    white-space: normal;
    word-wrap: break-word;
}

.reference-title-button-cb:hover {
    background-color: #f1f1f1;
}

.reference-title-button-cb.selected-cb {
    background-color: #e1e1e1;
    color: #007bff;
    border-color: #007bff;
}

.chat-header-cb {
    background-color: #00244c;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header-cb .toggle-icon-cb {
    font-size: 20px;
}

.chat-input-cb {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.chat-input-cb input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    word-wrap: break-word;
}

.chat-input-cb button {
    padding: 10px 20px;
    background-color: #00244c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
}

.chat-input-cb button:hover {
    background-color: #0056b3;
}

.message-content-cb.loading-cb {
    background-color: #f1f1f1;
    color: #999;
    font-style: italic;
}

/* Databricks link styles */
.databricks-link-cb {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white!important;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.databricks-link-cb:hover {
    background-color: #0056b3;
    color: white!important;
}

.suggested-questions-cb {
    margin-top: 10px;
}

.suggested-question-button-cb {
    background-color: #f1f1f1;
    border: 1px solid #00244c;
    color: #00244c;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    display: block;
    text-align: left;
    width: 100%;
}

.suggested-question-button-cb:hover {
    background-color: #e1e1e1;
}

.service-options-cb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.service-option-button-cb {
    background-color: #00244c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
}

.service-option-button-cb:hover {
    background-color: #0056b3;
}

/* Botón de cerrar referencias */
.close-references-button-cb {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-references-button-cb:hover {
    background-color: #c82333;
}

.references-header-cb {
    position: relative;
    padding: 10px;
}