:root {
  --bg:  #00244c;
  --bg-accent: #484a4d;
  --text-color: #ffffff;
  --nav-size: 33px;
  --border: 1px solid #00244c;
  --border-radius: 5px;
  --speed: 500ms; 
}
  
  .container-top-navbar{
    padding: 4px 0;
    background-color: #00244c;
    display: flex;
    justify-content: space-between;
    position: fixed;
    height: var(--nav-size);
    z-index: 1000;
    padding-left: 7px;
    width: 100%;
    top:0;
  }
  .container-top-navbar-right{
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .top-navbar-item {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-item{
    --button-size: calc(var(--nav-size)*0.5);
    font-size: var(--button-size);
    color: #ffffff !important;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
  }

  .container-top-navbar .icon-item:hover{
    filter: brightness(1.5);
    color: #ffffff !important;
  }

  .top-navbar-circle{
    width: calc(var(--nav-size)*0.9);
    height: calc(var(--nav-size)*0.9); 
    margin-right: 13px;
    margin-left: 13px;
    border-radius: 50%;
    background-color: #9d9d9d;
    color: #080808;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification-count {
    position: absolute;
    top: -6px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 1px 6px 0px 5px;
    font-size: 10px;
    font-weight: bold;
  }

  .container-top-navbar .icon-item .spacing{
    padding: 0 15px;
  }


  .container-top-navbar .logo{
    width: 55px;
    height: 50px;
    overflow: hidden;
    display: inline-block;
    padding-left: 5px;
  }

  .container-top-navbar .logo img{
    max-width: 100%;
    max-height: 100%;
  }

  .container-top-navbar .title{
    color: white;
    font-size: 20px;
    display: inline-block;
    position: absolute;
    left: 66px;
    top: 11px;
  }

  .search-section {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 3px 3px;
    margin-top: 3px;
    width: 400px;
    height: 26px;
  }
  
  .search-btn {
    background: none;
    border: none;
    color: #333; /* Color del icono de la lupa */
    font-size: 1.2rem;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .search-section input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    font-size: 1rem;
    background-color: transparent;
  }

.dropdown {
  position: absolute !important;
  top: 3.5rem;
  width: 234px;
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  color: var(--text-color);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  height: 144px !important;
  
}

.menu {
  width: 100%;
}

.dropdown-menu-item {
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  color: #fafafa !important;
}

.menu .dropdown-namecontainer{
  padding-bottom: 10px;
}
.menu .dropdown-namecontainer .dropdown-header-DS {
  font-weight: 900;
  font-size: 13px;
}

.menu .dropdown-namecontainer .dropdown-subheader {
  font-weight: 300;
  font-size: 9px;
}

.dropdown-menu-item .icon-button {
  margin-right: 0.5rem;
  font-size: 15px;
}

.dropdown-menu-item .text-button {
  margin-right: 0.5rem;
  font-size: 15px;
}



.dropdown-menu-item .icon-button:hover {
  filter: none;
}

.dropdown-menu-item:hover {
  background-color: #0c366d;
  color: #ffffff;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}