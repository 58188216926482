
.landingPage{
    background-color: #00244c;
    display: block;
    text-align: center;
    position: absolute;
    top:0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  
  .landingLogo img{
    margin-top: 15%;
    height: 15%;
    width: 15%;
    background-color: #00244c;
  }

  .landingTitle{
      margin-top: -5px;
      color: #FFFFFF;
      font-family: 'Nunito Sans',
      sans-serif !important;
      font-weight: 800;
  }

  .landingPage .grid-container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    position: relative;
    background-color: #00244c;
    padding: 0 100px;
  }


  .landingPage .grid-container .grid-item{
    align-items: center;
  }



  .landingPage .grid-container .grid-item .button-employee {
    width: 190px;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    margin-top: 2%;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: #d3d3d3;
    border-style:solid;
    color: #00244c;
  }

  .landingPage .grid-container .grid-item .button-client {
    width: 190px;
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    margin-top: 2%;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: #d3d3d3;
    border-style:solid;
    color: #00244c;
  }


  .landingPage .grid-container .grid-item .button2{
    display:inline-block;
    height: 40px;
    padding:3px;
    box-sizing: border-box;
    text-decoration:none;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: #d3d3d3;
    border-style:solid;
    text-transform:uppercase;
    font-weight:400;
    font-size: 18px;
    color:#FFFFFF;
    text-align:center;
    transition: all 0.15s;
    }
    a.button2:hover{
    color:#DDDDDD;
    border-color:#DDDDDD;
    }
    a.button2:active{
    color:#BBBBBB;
    border-color:#BBBBBB;
    }
    @media all and (max-width:30em){
      .landingPage .grid-container .grid-item .button2{
        display:block;
        margin:0.4em auto;
        font-size: 12px;
      }
    }