.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-target {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  top: 70px;
  left: 50%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transform: translateX(-50%);
  z-index: 10000;
  width: 250px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  animation: zoomIn 0.1s ease-out forwards;
}

.tooltip-content {
  padding: 2px;
}

.tooltip-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
@keyframes zoomIn {
  from {
    transform: translateX(-50%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
}
