body {
  margin: 0;
  font-family: 'Nunito Sans',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  padding-left: 0px !important;
}

.col-6-DS{
  width: 70% !important;
}

html{
  scroll-behavior: smooth;
}

.col-6-DSR{
  width: 60% !important;
}

.container-top-navbar .title  h4{
  font-size: 20px !important;
  font-weight: 700 !important;
}

.home-DSR h3{
  font-size: 20px !important;
  font-weight: 700 !important;
}

.container-SDR {
  padding: 41px 10px 0 10px !important; 
  margin-left: 265px !important;
  height: 100% !important;
  transition: margin-left 0.01s ease-in !important;
}

.container-SDR.inactive{
  margin-left: 70px !important;
  padding: 50px 15px 0px 15px !important;
}